@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


body {
    @apply bg-dark;
}

/* Hide all scrollbars */
* {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
}
